<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb >
            <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2"  v-loading="screenLoading"
              element-loading-lock="true"
              :element-loading-text="screenText"
              element-loading-customClass="loading-large"
              element-loading-spinner="el-icon-loading">
        <div>

            <form>
                <div class="preview-reservation">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="bx-head " :class="bgInfo(info_page.action, (reservation.channel &&  reservation.channel=== 'ratetiger'))">
                                <label class="mid">
                                    <span>
                                       {{reservation.name?reservation.name+' '+reservation.last_name:''}}
                                    </span>
                                    <span>
                                       ID de la reservación: {{reservation._id}}
                                    </span>
                                </label>
                                <label class="mid">
                                    <span class="stat-cancel" v-if="(info_page.action === 'cancel')">
                                        RESERVACIÓN CANCELADA
                                    </span>
                                    <span class="ratetiger" v-if="reservation.channel &&  reservation.channel === 'ratetiger'">
                                        RESERVACIÓN RATETIGER  -  ID RATETIGER: {{reservation.ratetiger_id}} -  UNIQUE ID: {{reservation.unique_id}}
                                    </span>

                                    <span>
                                       {{reservation.currency}} ${{formatMoney(reservation.total)}}
                                    </span>
                                </label>
                                <!-- <span class="fa fa-print icon-action"></span>
                                <span class="fa fa-paper-plane icon-action plus-right"></span> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="bx-information">
                                <div class="bx-head">
                                    <label class="comp">Información de la reservación</label>
                                </div>
                                <div class="box-body">
                                    <!-- <div class="item-line">
                                        <label>No. confirmación </label>
                                        <label  v-if="reservation_info.show_preview">{{reservation._id}} </label>
                                        <fg-input type="text"
                                                  v-model="reservation._id"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div> -->
                                    <div class="item-line">
                                        <label>Fecha de llegada </label>
                                        <label v-if="reservation_info.show_preview"> {{formatConverte(reservation.date_arrival)}} </label>

                                        <fg-input v-else disabled :value="formatConverte(reservation.date_arrival)">
                                           <!-- <el-date-picker v-model="reservation.date_arrival" type="date"
                                                            format="dd-MM-yyyy"
                                                            :picker-options="pickerOptions1" @change="pickDate()">
                                            </el-date-picker>-->
                                        </fg-input>
                                    </div>
                                    <div class="item-line">
                                        <label>Fecha de salida </label>
                                        <label v-if="reservation_info.show_preview"> {{formatConverte(reservation.date_departure)}} </label>
                                        <fg-input v-else disabled :value="formatConverte(reservation.date_departure)">
                                           <!-- <el-date-picker v-model="reservation.date_departure" type="date"
                                                            format="dd-MM-yyyy"
                                                            :picker-options="pickerOptions1" @change="pickDate()">
                                            </el-date-picker>-->
                                        </fg-input>
                                    </div>

                                    <div class="item-line" v-if="reservation.landing_id">
                                        <label>Landing Page </label>
                                        <label v-if="reservation_info.show_preview">{{reservation.landing_data.name}} </label>
                                        <fg-input type="text"
                                                  :value="reservation.landing_data.name"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>


                                    <div class="item-line">
                                        <label>Tipo de habitación </label>
                                        <label v-if="reservation_info.show_preview">{{info_page.dataCompleted?reservation.room.name:'--'}} </label>
                                        <fg-input type="text"
                                                  :value="info_page.dataCompleted?reservation.room.name:'--'"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>
                                    <div class="item-line">
                                        <!-- <label>No. Habitaciones </label> -->
                                       <!-- <label class="item-quarter" v-if="reservation_info.show_preview">{{reservation.room_number}} </label>
                                        <fg-input type="text"
                                                  v-model="reservation.room_number"
                                                  class="item-third"
                                                  v-else

                                                  style="width: 50%"

                                        >
                                        </fg-input>-->
                                        <label>Plan de alimentos</label>
                                        <label v-if="reservation_info.show_preview">{{info_page.dataCompleted?info_page.contract_types[reservation.rate_data.meal_plan].name:'--'}} </label>

                                        <fg-input type="text"
                                                  :value="info_page.dataCompleted?info_page.contract_types[reservation.rate_data.meal_plan].name:'--'"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>
                                    <div class="item-line" v-if="reservation.ratetiger_rateplan!==undefined && reservation.ratetiger_rateplan!==null">
                                       
                                        <label>Contrato recibido por Ratetiger</label>
                                        <label v-if="reservation_info.show_preview">{{reservation.ratetiger_rateplan}} </label>

                                        <fg-input type="text"
                                                  :value="reservation.ratetiger_rateplan"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>

                                    <div class="item-line" v-if="reservation.rateplan_data!==undefined">

                                        <label>Contrato</label>
                                        <label v-if="reservation_info.show_preview">{{reservation.rateplan_data.name}} </label>

                                        <fg-input type="text"
                                                  :value="reservation.rateplan_data.name"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>


                                    <!--
                                   <div class="item-line" v-if="reservation.promo.length">
                                        <label>Nombre de la promoción </label>
                                        <label v-if="reservation_info.show_preview">{{reservation.promo.length?reservation.promo[0].name:'' }} </label>
                                        <fg-input type="text"
                                                  v-model="reservation.promo[0].name"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>-->
                                    <div class="item-line">
                                        <label>Adultos </label>
                                        <label v-if="reservation_info.show_preview" class="item-third">{{info_page.dataCompleted?reservation.rate_data.adults:'--'}} </label>
                                        <fg-input type="text"
                                                  class="item-third"
                                                  :value="info_page.dataCompleted?reservation.rate_data.adults:'--'"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                        <label class="item-third-b" :class="reservation_info.show_preview?'':'pl-2'">Jrs </label>
                                        <label v-if="reservation_info.show_preview" class="item-third">{{info_page.dataCompleted?reservation.rate_data.jrs:'--'}} </label>
                                        <fg-input type="text"
                                                  class="item-third"
                                                  :value="info_page.dataCompleted?reservation.rate_data.jrs:'--'"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>
                                    <div class="item-line">
                                        <label>Niños </label>
                                        <label v-if="reservation_info.show_preview" class="item-third">{{info_page.dataCompleted?reservation.rate_data.children:'--'}} </label>
                                        <fg-input type="text"
                                                  class="item-third"
                                                  :value="info_page.dataCompleted?reservation.rate_data.children:'--'"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                        <label class="item-third-b" :class="reservation_info.show_preview?'':'pl-2'">Infantes </label>
                                        <label v-if="reservation_info.show_preview" class="item-third">{{info_page.dataCompleted?reservation.rate_data.infants:'--'}} </label>
                                        <fg-input type="text"
                                                  class="item-third"
                                                  :value="info_page.dataCompleted?reservation.rate_data.infants:'--'"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>
                                    <div class="item-line">
                                        <label>Fecha de la reservación </label>
                                        <label v-if="reservation_info.show_preview"> {{formatConverte(checkTimeZone(reservation.createdAt))}} </label>
                                        <fg-input type="text"
                                                  :value="formatConverte(checkTimeZone(reservation.createdAt), 'txt')"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>
                                    <div class="item-line">
                                       <label>Hora de la reservación </label>
                                        <label v-if="reservation_info.show_preview"> {{formatConverte(checkTimeZone(reservation.createdAt), 'time')}} </label>
                                        <fg-input type="text"
                                                  :value="formatConverte(checkTimeZone(reservation.createdAt), 'time')"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>
                                    <!--<div class="item-line mt-4">
                                        <label>Razón de la cancelación </label>
                                        <label v-if="reservation_info.show_preview">{{reservation.cancel_reason}} </label>
                                        <fg-input type="text"
                                                  v-model="reservation.cancel_reason"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>-->
                                    <div class="item-line">
                                        <label>Última modificación </label>
                                        <label  v-if="reservation_info.show_preview"> {{info_page.dataCompleted?formatConverte(checkTimeZone(reservation.rate_data.updatedAt)):formatConverte(checkTimeZone(reservation.createdAt))}} </label>
                                        <fg-input type="text"
                                                  :value="info_page.dataCompleted?formatConverte(checkTimeZone(reservation.rate_data.updatedAt), 'txt'):formatConverte(checkTimeZone(reservation.createdAt), 'txt')"
                                                  v-else
                                                  disabled
                                        >
                                        </fg-input>
                                    </div>
                                    <div class="item-line" v-if="reservation.special_request">
                                        <label  v-if="reservation_info.show_preview">Requerimientos especiales </label>
                                        <label style="vertical-align: top" v-else>Requerimientos especiales </label>
                                        <label  v-html="reservation.special_request"></label>
                                        <!--<fg-input
                                                 v-model="reservation.special_request"
                                                 v-else
                                                 disabled
                                                >
                                            <textarea class="form-control"
                                                      v-model="reservation.special_request" style="resize: none;" disabled></textarea>
                                        </fg-input>
                                        <fg-input type="text"
                                                  v-model="reservation.client_info.country"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                </div>




                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="bx-information">
                                <div class="bx-head">
                                    <label class="comp">Información del huésped</label>
                                </div>
                                <div class="box-body huesped">
                                    <div class="item-line">
                                        <label>Nombre </label>
                                        <label >{{reservation.name+' '+reservation.last_name}} </label>
                                        <!--<fg-input type="text"
                                                  v-model="reservation.client_info.name"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                    <div class="item-line">
                                        <label>Email </label>
                                        <label >{{reservation.email}} </label>
                                        <!--<fg-input type="text"
                                                  v-model="reservation.client_info.email"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                    <div class="item-line">
                                        <label>Telefono </label>
                                        <label>{{reservation.phone}} </label>
                                       <!-- <fg-input type="text"
                                                  v-model="reservation.client_info.phone"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                    <div class="item-line">
                                        <label>Domicilio </label>
                                        <label>{{reservation.address}} </label>
                                       <!-- <fg-input type="text"
                                                  v-model="reservation.client_info.address"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                    <div class="item-line">
                                        <label>Ciudad </label>
                                        <label >{{reservation.city}} </label>
                                        <!--<fg-input type="text"
                                                  v-model="reservation.client_info.city"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                    <div class="item-line">
                                        <label>Estado </label>
                                        <label >{{reservation.state}} </label>
                                        <!--<fg-input type="text"
                                                  v-model="reservation.client_info.city"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                    <!--<div class="item-line">
                                        <label>Estado </label>
                                        <label>{{reservation.client_info.state}} </label>-->
                                        <!--<fg-input type="text"
                                                  v-model="reservation.client_info.state"
                                                  v-else
                                        >
                                        </fg-input>
                                    </div>-->
                                    <div class="item-line">
                                        <label>CP </label>
                                        <label>{{reservation.zc}} </label>
                                       <!-- <fg-input type="text"
                                                  v-model="reservation.client_info.zc"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                    <div class="item-line">
                                        <label>País </label>
                                        <label>{{translateCountry(reservation.country)}} </label>
                                        <!--<fg-input type="text"
                                                  v-model="reservation.client_info.country"
                                                  v-else
                                        >
                                        </fg-input>-->
                                    </div>
                                </div>
                                <div class="item-line mt-3 state-reservation">
                                    <label>Estado de la reservación:  </label>
                                    <label v-if="reservation_info.show_preview" class="ml-3 text-uppercase font-weight-bold" :class="addSemaforo(reservation)">{{ textSemaforo(reservation)}} </label>

                                    <fg-input type="text"
                                              class="input-default"
                                              v-else
                                              v-model="reservation.status">

                                        <el-select class="select-default" size="large"  :name="$t('contract.form.link_type')"
                                                   v-model="reservation.status">
                                            <el-option v-for="option in info_page.states" class="select-default" :value="option.value"
                                                       :label="option.name" :key="option.value">
                                            </el-option>
                                        </el-select>
                                    </fg-input>

                                </div>


                                <div class="text-center mt-3 mb-3" v-if="!reservation_info.show_preview">
                                    <button type="submit" class="btn btn-success btn-fill btn-wd mr-2" :disabled="reservation.status===statusBD" @click.prevent="sendForm(info_page.action)">
                                        {{info_page.button}}
                                    </button>
                                    <button type="buttton" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: 'Reservations'})">
                                        {{$t('form.cancel')}}
                                    </button>
                                    <!-- <button type="buttton" class="btn btn-danger btn-fill btn-wd ml-2" @click.prevent="checkConfigsActives()">
                                         Activos
                                     </button>-->

                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6">
                            <div class="bx-information">
                                <div class="bx-head">
                                    <label class="comp">Información del pago</label>
                                </div>
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                           <div class="item-line">
                                                <label>Tipo de pago </label>
                                                <label class=" w-30p" style="width: 250px">{{paymentType(reservation.payment_method)}} </label>
                                            </div>
                                            <div class="item-line" v-if="reservation.payment_method === 'TDD' || reservation.payment_method === 'PAYPAL' || reservation.payment_method === 'FIRSTDATA' ||  reservation.payment_method === 'STRIPE'">
                                                <label>ID transacción </label>
                                                <label v-if="(reservation.payment_method === 'PAYPAL' || reservation.payment_method === 'TDD' || reservation.payment_method === 'FIRSTDATA'  ||  reservation.payment_method === 'STRIPE' ) && reservation.payment_status === false " > Proceso de pago incompleto </label>
                                                <label v-else> {{reservation.payment_id}} </label>
                                            </div>
                                            <div class="item-line" v-show="reservation.payment_status && (reservation.payment_method === 'TDD' || reservation.payment_method === 'PAYPAL' || reservation.payment_method === 'FIRSTDATA'  ||  reservation.payment_method === 'STRIPE')">
                                                <label>Depósito pagado </label>
                                                <label> {{info_page.dataCompleted?formatConverte(this.checkTimeZone(reservation.rate_data.createdAt)):'--'}} </label>
                                            </div>
                                            <div class="item-line align-content-end" >
                                                <div v-if="reservation.rate_data!==undefined">
                                                    <label>Tarifa por día</label><br/>

                                                    <div v-for="(rate, idx) in reservation.rate_data.rates" style="width: calc(50% + 200px)">
                                                        <label style="width: calc(100% - 200px)"> {{sumDays(reservation.date_arrival, idx)}} </label>
                                                        <label class=" w-30p " >
                                                            <span class="text-danger" style="width: 30%; display: inline-block; text-decoration: line-through; margin-right: 15px"
                                                                  v-if="reservation.rate_data.rates_no_promo.length && reservation.rate_data.rates_no_promo[idx] && reservation.rate_data.rates_no_promo[idx] !== rate">
                                                                {{reservation.rate_data.rates_no_promo[idx]!==null?'$'+formatMoney(reservation.rate_data.rates_no_promo[idx]):''}}
                                                            </span>
                                                            <span v-if="reservation.rate_data.rates_no_promo.length && reservation.rate_data.rates_no_promo[idx]===null || reservation.rate_data.rates_no_promo[idx] === rate"  style="width: 30%; display: inline-block; text-decoration: line-through; margin-right: 15px"></span>
                                                            <span style="width: 30%; display: inline-block;">
                                                                {{rate>0?'$'+formatMoney(rate):'Gratis'}}

                                                            </span>
                                                        </label>
                                                    </div><br>
                                                </div>


                                                <label v-if="reservation.addons!==undefined && reservation.addons.length">Subtotal de reserva </label>
                                                <label v-else>Subtotal </label>
                                                <label class="w-30p">${{formatMoney(reservation.subtotal)}} </label>

                                                <label v-if="reservation.addons!==undefined && reservation.addons.length">Impuestos de reserva </label>
                                                <label v-else>Impuestos </label>
                                                <label class=" w-30p">${{formatMoney(reservation.taxes)}} </label><br>


                                                <label v-if="reservation.addons!==undefined && reservation.addons.length">Subtotal de servicios agregados </label>
                                                <label v-if="reservation.addons!==undefined && reservation.addons.length" class="w-30p">${{formatMoney(reservation.total_addons/1.16)}} </label>
                                                <label v-if="reservation.addons!==undefined && reservation.addons.length">Impuestos de servicios agregados</label>
                                                <label v-if="reservation.addons!==undefined && reservation.addons.length" class=" w-30p">${{formatMoney(reservation.total_addons - reservation.total_addons/1.16)}} </label><br>

                                                <label>Total </label>
                                                <label class=" w-30p">${{formatMoney(reservation.total)}} ({{reservation.currency}})</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="bx-information mb-2" v-if="reservation.addons!==undefined && reservation.addons.length">
                                <div class="bx-head">
                                    <label class="comp">Servicios extra agregados</label>
                                </div>
                                <div class="box-body addons" style="min-height: 100px">
                                    <div class="inside-addons " :class="reservation.addons.length>1?'border-bottom-sep':''" v-for="addon in reservation.addons" >

                                            <div class="item-line " >
                                                <label>{{capitalize(addon.name)}} </label>
                                            </div>

                                            <div class="item-line third-size-a">
                                                <label> {{ addon.date!==undefined?'Fecha:'+formatConverte(checkTimeZone(addon.date), 'txt'):'Contactar para agendar'}}</label>
                                            </div>
                                        <div class="item-line third-size-b ">
                                            <label >Subtotal: <span>${{formatMoney(addon.subtotal)}} </span> </label>
                                        </div>
                                            <div class="item-line third-size-a" v-if="checkPriceType(addon.price_type, [2,4])">
                                                <label>Adultos: <span>{{addon.qty}}</span></label>
                                                <label v-if="addon.qty_juniors!==undefined && addon.qty_juniors>0" >Juniors: <span>{{addon.qty_juniors}}</span> </label>
                                                <label v-if="addon.qty_children!==undefined && addon.qty_children>0" >Niños: <span>{{addon.qty_children}}</span> </label>
                                            </div>
                                            <div class="item-line third-size-a" v-else>
                                                <label>Cantidad: <span>{{addon.qty}}</span></label>
                                            </div>
                                    </div>
                                </div>
                            </div>


                            <div class="bx-information" >
                                <div class="bx-head">
                                    <label class="comp">Información de la promoción</label>
                                </div>
                                <div class="box-body huesped" style="min-height: 100px">






                                    <div class="row" v-if="reservation.rate_data!==undefined && reservation.rate_data!==undefined && reservation.rate_data.promos !== undefined &&
                                    reservation.rate_data.promos && reservation.rate_data.promos.length">
                                        <div class="col-md-12">

                                            <div class="item-line">
                                                <el-tabs v-model="activeName" @tab-click="handleClick">
                                                    <el-tab-pane :label="promotion.name" :name="promotion._id" v-for="promotion in reservation.rate_data.promos">

                                                        <div class="item-line" >
                                                            <label >Nombre </label>
                                                            <label >{{promotion.name}} </label>
                                                        </div>
                                                        <div class="item-line" >
                                                            <label>Descuento</label>
                                                            <label v-if="promotion.type===0">{{promotion.value+' '+promotion.discount_type}}</label>
                                                            <label v-if="promotion.type===1">
                                                                <ul class="reorder-list">
                                                                    <li v-for="(valueDay, idxK) in promotion.value_days" :class="idxK===0?'ordL':'ordF'">{{ dayName(idxK)+' '+ valueDay+' %'}}</li>
                                                                </ul>
                                                            </label>
                                                        </div>
                                                        <div class="item-line" v-if="promotion.free_night_active && promotion.free_night<=diffDays(reservation.date_arrival,  reservation.date_departure)">
                                                            <label >Noche gratis</label>
                                                            <label >{{nightDiscount(promotion.free_night)+' noche gratis'}}  {{(promotion.free_night_limit_to_stay)?'(La noche gratis aplica una por reservación)':''}}</label>
                                                        </div>

                                                        <div class="item-line" v-if="reservation.rate_data.meal_plan===3 && promotion.free_jrs_active && info_page.dataCompleted && reservation.rate_data.jrs>0">
                                                            <label >Juniors gratis </label>
                                                            <label >{{  constructString(promotion.free_jrs, promotion.free_jrs_type, 'Junior', promotion.free_jrs_night)}} </label>
                                                        </div>
                                                        <div class="item-line" v-if="promotion.free_children_active && info_page.dataCompleted && reservation.rate_data.children>0">
                                                            <label >Niños gratis </label>
                                                            <label >{{  constructString(promotion.free_children, promotion.free_children_type, 'niño', promotion.free_children_night)}} </label>

                                                        </div>


                                                        <div class="item-line" v-if="promotion.aggregated_active">
                                                            <label >Valores agregados </label>
                                                            <label style="width: 100%">
                                                                <ul>
                                                                    <li v-for="(valueAgr, idxK) in promotion.aggregated_values">{{ nameAggregate(valueAgr.name)+': '+valueAgr.text_es}}</li>
                                                                </ul>
                                                            </label>
                                                        </div>


                                                    </el-tab-pane>
                                                </el-tabs>
                                            </div>

                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="item-line" >
                                            <label>Tarifa sin promoción</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--<div class="row">
                        <div class="col-md-12">
                            <div class="bx-offers">
                                <div class="bx-head">
                                    <label class="comp">{{reservation.offer}}</label>
                                </div>
                                <div class="box-body">
                                    <div class="row item-row">
                                        <div class="col-md-3">
                                            <div class="item-line">
                                                <label class="is-bold">Fecha</label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="item-line">
                                                <label class="is-bold">Descripción </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="item-line">
                                                <label class="is-bold">Importe </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row item-row" v-for="offer in reservation.offers_apply.items">
                                        <div class="col-md-3">
                                            <div class="item-line">
                                                <label>{{formatConverte(offer.date)}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="item-line">
                                                <label>{{offer.description}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="item-line">
                                                <label>{{offer.importe}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row item-row pt-4 bg-white">
                                        <div class="col-md-3 offset-3">
                                            <div class="item-line complete">
                                                <label class="is-bold">Subtotal </label>
                                                <label class="is-bold">Total de la reserva </label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="item-line complete">
                                                <label class="is-bold">{{reservation.offers_apply.subtotal}} </label>
                                                <label class="is-bold">{{reservation.offers_apply.total}} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>-->



                    <div class="row">
                        <div class="col-md-6">

                            <div class="bx-history">
                                <div class="bx-head">
                                    <label class="comp">Historial de la reserva</label>
                                </div>
                                <div class="box-body pb-big pt-3">
                                    <el-timeline>
                                        <el-timeline-item
                                                v-for="(activity, index) in activities"
                                                :key="index"
                                                :icon="activity.icon"
                                                :type="activity.type"
                                                :color="activity.color"
                                                :size="activity.size"
                                                :timestamp="activity.timestamp">
                                            {{activity.content}}
                                        </el-timeline-item>
                                    </el-timeline>
                                </div>
                            </div>
                        </div>
                       <div class="col-md-6">
                            <div class="bx-politics" v-if="(reservation_info.annulment)">
                                <div class="bx-head">
                                    <label class="comp">Política de cancelación</label>
                                </div>
                                <div class="box-body" v-if="(reservation_info.annulment.refundable)">

                                    <div class="item-line">
                                        <label>Periodo de penalización: </label>
                                        <label> {{(reservation_info.annulment.cancellation_time)? annulmentConverte(reservation_info.annulment.cancellation_time, null, 'periodo'):''}} </label>
                                    </div>
                                    <div class="item-line">
                                        <label>Dentro del perido de penalización: </label>
                                        <label> {{(reservation_info.annulment.in_time_value)? annulmentConverte(reservation_info.annulment.in_time_value, reservation_info.annulment.in_time_type, 'amount'):'Nada'}} </label>
                                    </div>
                                    <div class="item-line">
                                        <label>Fuera del periodo de penalización: </label>
                                        <label> {{(reservation_info.annulment.out_time_value)? annulmentConverte(reservation_info.annulment.out_time_value, reservation_info.annulment.out_time_type, 'amount'):'Nada'}} </label>
                                    </div>

                                </div>
                                <div class="box-body" v-else>
                                  <div class="item-line">
                                        <label>NO REEMBOLSABLE</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="bx-terms">
                                <div class="bx-head">
                                    <label class="comp">Políticas de pago</label>
                                </div>
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <p v-if="reservation_info.property" v-html="reservation_info.property.payment_policy"></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix"></div>
            </form>
        </div>
    </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import { Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import {DatePicker, Select, Option, Timeline, TimelineItem, Tabs, TabPane} from 'element-ui'
    //import moment from 'moment';
    import moment from 'moment-timezone';
    import PropertyService from '../../../js/services/PropertyService';
    import ReservationService from '../../../js/services/ReservationService';
    import RoomService from '../../../js/services/RoomService';
    import Settings from "src/js/helpers/Settings";
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    const propertyService = new PropertyService();
    const reservationService = new ReservationService();
    const roomService = new RoomService();
    import { mapGetters } from "vuex";
    let settings = new Settings();
    import ApiFrontService from '../../../services/ApiFrontService';
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    const logFunctions = new LogFunctions();
    const logService = new LogService();



    export default {
        components: {
            Breadcrumb,
            BreadcrumbItem,
            [Select.name]: Select,
            [Option.name]: Option,
            [Timeline.name]: Timeline,
            [TimelineItem.name]: TimelineItem,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
            [DatePicker.name]: DatePicker,
        },
        computed: {
          ...mapGetters(["currentUser","selected_property"]),
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                statusBD: false,
                activeName: null,
                activities: [],

                aggregates:[
                    {
                        name: 'Crédito en resort',
                        value: 'resort-credit'
                    },
                    {
                        name: 'Crédito en spa',
                        value: 'spa-credit'
                    },
                    {
                        name: 'Tour',
                        value: 'tour'
                    },
                    {
                        name: 'Crédito en golf',
                        value: 'golf'
                    },
                    {
                        name: 'Botella de vino',
                        value: 'bottle-of-wine'
                    },
                    {
                        name: 'Desayuno',
                        value: 'breakfast'
                    },
                    {
                        name: 'Cena',
                        value: 'dinner'
                    },
                    {
                        name: 'Mini bar',
                        value: 'mini-bar'
                    },
                    {
                        name: 'Ascensó de categoría de habitación',
                        value: 'free-upgrade'
                    },
                    {
                        name: 'Wi-Fi',
                        value: 'internet'
                    },
                    {
                        name: 'Llegada temprano',
                        value: 'early-check-in'
                    },
                    {
                        name: 'Salida tarde',
                        value: 'late-check-out'
                    },
                    {
                        name: 'Cargo por uso de instalaciones del resort',
                        value: 'resort-free'
                    },
                    {
                        name: 'Registro VIP',
                        value: 'vip-check-in'
                    },

                ],

                breadcrumbs:[
                    {
                        name: this.$t('reservation.breadcrumb.index'),
                        path: '/admin/reservations',
                        type: '',
                    },

                    {
                        name: this.$t('reservation.breadcrumb.resume'),
                        path: '',
                        type: 'active',
                    }

                ],
                info_page:{
                    original_info:{},
                    section_name: 'reservation',
                    action: 'view',
                    header: this.$t('reservation.breadcrumb.resume'),
                    button:  this.$t('reservation.insides.update.button'),
                    tab_lang: 'es',
                    timezoneIs: moment.tz.guess(),
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    dataCompleted: false,
                    room: null,
                    rooms: [],
                    states:[
                        {
                            name: 'Realizada',
                            value: 1
                        },
                        {
                            name: 'Confirmada',
                            value: 2
                        },
                        {
                            name: 'Cancelada',
                            value: 3
                        }
                    ],
                    contract_types: [
                        {},
                        {
                            _id: 1,
                            name: 'Solo habitación (EP)',
                            status: false,
                            filter: true
                        },
                        {
                            _id: 2,
                            name: 'Habitación + desayuno',
                            status: false,
                            filter: true
                        },
                        {
                            _id: 3,
                            name: 'Todo incluido (AI)',
                            status: false,
                            filter: true
                        }
                    ],
                    paymentTypes: [
                        {
                            _id:'ARRIVE',
                            name:'Pago en destino'
                        },
                        {
                            _id:'TDD',
                            name:'Tarjeta de Crédito / Débito'
                        },
                        {
                            _id:'PAYPAL',
                            name:'Paypal'
                        },
                        {
                            _id: 'VALIDATECARD',
                            name: 'Verificación de tarjeta',
                        },
                        {
                            _id: 'FIRSTDATA',
                            name: 'Tarjeta de Crédito / Débito (FIRSTDATA)',
                        },
                        {
                            _id: 'STRIPE',
                            name: 'Tarjeta de Crédito / Débito (STRIPE)',
                        }
                    ],
                    countries: [
                        {
                            'name':'México',
                            'arrayNames': ['México', 'Mexico'],
                            'value': 'mx'
                        },

                        {
                            'name':'Canadá',
                            'arrayNames': ['Canadá', 'Canada'],
                            'value': 'ca'
                        },

                        {
                            'name':'Estados Unidos',
                            'arrayNames': ['Estados Unidos', 'United States of America'],
                            'value': 'us'
                        }
                    ]
                },

                fuseSearch: null,
                reservation:{
                    name: '',
                    promo:[],
                    rate_data: {},
                    payment_method: 0
                },
                reservation_info:{
                    show_preview: true,
                    rateplan:null,
                    annulment:null,
                    property: null,
                    states: ['', 'Realizada', 'Confirmada', 'Cancelada', 'Sin concluir'],
                    cancellation_times:[
                        {
                            name: '2 horas',
                            value: 2,
                        },
                        {
                            name: '6 horas',
                            value: 6,
                        },
                        {
                            name: '8 horas',
                            value: 8,
                        },
                        {
                            name: '24 horas',
                            value: 24,
                        },
                        {
                            name: '48 horas',
                            value: 48,
                        },
                        {
                            name: '72 horas',
                            value: 72,
                        },
                        {
                            name: '4 días (96 horas)',
                            value: 96
                        },
                        {
                            name: '5 días (120 horas)',
                            value: 120
                        },
                        {
                            name: '6 días (144 horas)',
                            value: 144
                        },
                        {
                            name: '1 semana (168 horas)',
                            value:168
                        },
                        {
                            name: '2 semanas (336 horas)',
                            value: 336
                        },
                        {
                            name: '3 semanas (504 horas)',
                            value: 504
                        },
                        {
                            name: '4 semanas (672 horas)',
                            value: 672
                        },
                        {
                            name: '1 mes (720 horas)',
                            value: 720
                        },
                        {
                            name: '6 semanas (1008 horas)',
                            value: 1008
                        },
                        {
                            name: '45 días (1080 horas)',
                            value: 1080
                        },
                        {
                            name: '7 semanas (1176 horas)',
                            value: 1176
                        },
                        {
                            name: '8 semanas (1344 horas)',
                            value: 1344
                        },
                        {
                            name: '60 días (1440 horas)',
                            value: 1440
                        },
                        {
                            name: '90 días (2160 horas)',
                            value: 2160
                        }
                    ],
                    period_types:[
                        {
                            _id: 1,
                            type: 0,
                            name: 'Nada',
                            value: 0
                        },
                        {
                            _id: 2,
                            type: 1,
                            name: 'Tasa fija',
                            value: 500
                        },
                        {
                            _id: 3,
                            type: 2,
                            name: 'Primera noche + impuestos',
                            value: 1
                        },
                        {
                            _id: 4,
                            type: 2,
                            name: '2 noches + impuestos',
                            value: 2
                        },
                        {
                            _id: 5,
                            type: 3,
                            name: '10% de la estancia + tasas',
                            value: 10
                        },
                        {
                            _id: 6,
                            type: 3,
                            name: '20% de la estancia + tasas',
                            value: 20
                        },
                        {
                            _id: 7,
                            type: 3,
                            name: '30% de la estancia + tasas',
                            value: 30
                        },
                        {
                            _id: 8,
                            type: 3,
                            name: '40% de la estancia + tasas',
                            value: 40
                        },
                        {
                            _id: 9,
                            type: 3,
                            name: '50% de la estancia + tasas',
                            value: 50
                        },
                        {
                            _id: 10,
                            type: 3,
                            name: '60% de la estancia + tasas',
                            value: 60
                        },
                        {
                            _id: 11,
                            type: 3,
                            name: '70% de la estancia + tasas',
                            value: 70
                        },
                        {
                            _id: 12,
                            type: 3,
                            name: '80% de la estancia + tasas',
                            value: 80,
                        },
                        {
                            _id: 13,
                            type: 3,
                            name: '90% de la estancia + tasas',
                            value: 90
                        },
                        {
                            _id: 14,
                            type: 3,
                            name: '100% de la estancia + tasas',
                            value: 100
                        }
                    ],
                },
                pickerOptions1: {
                },
                datePicker: '',

            }
        },

        mounted() {
        },
        created(){
            this.initPage(this.selected_property._id);
            moment.locale('es');
        },
        methods: {
            async initPage(property_id){

                this.initLoading();
                let reservation_id = this.$route.params.id;
                this.configLogs(property_id, this.info_page.section_name);

                await this.updateData(reservation_id, property_id);

                this.reservation_info.show_preview = true;

                if(this.$route.name==='EditReservation'){
                    this.reservation_info.show_preview = false;

                    this.info_page.action = 'edit';
                    this.log_page.action = 1;
                    this.info_page.header = this.$t('reservation.breadcrumb.edit');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('reservation.breadcrumb.edit');
                }


            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },

            async updateData(reservation_id, property_id) {
                //console.log('responses', reservation_id, property_id)
                let response = await reservationService.getReservationOne(reservation_id);
                response = response.data.data.reservation;

                this.info_page.original_info = {...response}

                this.info_page.rooms = [];

                /*let rateplan = await contractService.getContractId('5dd6dc89026b56001786f85e')
                rateplan = rateplan.data.data.rateplan;*/
                let property =this.selected_property
                //property = property.data.data.property;
                let paymnt = {};

                paymnt = this.info_page.contract_types.find(payment => payment._id === 1);
                paymnt.status = property.plan_ep;
                paymnt = this.info_page.contract_types.find(payment => payment._id === 2);
                paymnt.status = property.plan_epb;
                paymnt =  this.info_page.contract_types.find(payment => payment._id === 3);
                paymnt.status = property.plan_ai;


                response.room = null;
                if(response.rate_data!==undefined){
                    this.info_page.dataCompleted = true;
                }
                //console.log('response.rate_data', response.rate_data);
                if(response.rate_data.promos!== undefined && response.rate_data.promos && response.rate_data.promos.length){
                    this.activeName = response.rate_data.promos[0]._id;
                }
                let activities = [];

                activities.push({
                    content: 'Fecha de reservación',
                    timestamp: this.formatConverte(this.checkTimeZone(response.createdAt), 'time-txt')+' hrs'
                })

                this.activities = activities;


                let status =  response.status;

                if(response.payment_status===false && (response.payment_method === 'PAYPAL' || response.payment_method === 'FIRSTDATA' || response.payment_method === 'TDD') && status!==3){
                    this.info_page.states[0].name = this.textSemaforo(response)
                }



                if(this.info_page.dataCompleted){

                    //onsole.log('response', this.info_page.dataCompleted)
                    let annulment = response.rate_data.cancellation_policy
                    this.reservation_info.annulment = annulment;


                    let roomInfo = {};

                    if(response.room_data!==undefined){
                        roomInfo = response.room_data;
                    }else{
                        roomInfo =   await roomService.getRoomId(response.rate_data.room)
                        roomInfo = roomInfo.data.data.room;
                    }

                    this.info_page.room = roomInfo;
                    response.room = roomInfo;

                }else{

                    response.status = 4
                }
                response.name_cancel_user = '';

                if(response.status>=2 &&  (response.confirmed_user || response.confirmed_user===null) && response.confirmed_at && response.confirmed_at!==null){


                    if(response.confirmed_user && response.confirmed_user!==null){
                        let userInfo = await propertyService.getUserId(response.confirmed_user)
                        userInfo = userInfo.data.data.user;
                        response.name_confirmed_user = userInfo.first_name+' '+userInfo.last_name;
                    }else{
                        response.name_confirmed_user = 'Confirmada mediante pago'
                    }

                    activities.push({
                        content: 'Fecha de confirmación',
                        timestamp: this.formatConverte(this.checkTimeZone(response.confirmed_at), 'time-txt')+' hrs - '+response.name_confirmed_user,
                        color: '#1FBF64'
                    })
                }


                if(response.status===3 &&  (response.cancelled_user || response.cancelled_user===null) && response.cancelled_at && response.cancelled_at!==null){

                    if(response.cancelled_user && response.cancelled_user!==null){
                        let userInfo = await propertyService.getUserId(response.cancelled_user)
                        userInfo = userInfo.data.data.user;
                        response.name_cancel_user = userInfo.first_name+' '+userInfo.last_name;
                    }else{
                        response.name_cancel_user = 'Cancelada por el cliente'
                    }

                    activities.push({
                        content: 'Fecha de cancelación',
                        timestamp: this.formatConverte(this.checkTimeZone(response.cancelled_at), 'time-txt')+' hrs - '+response.name_cancel_user,
                        color: '#cf2a27'
                    })
                }

                let roomInfo = {};


                this.reservation_info.property = property;
                if(response.status===false){
                    response.status = 3;
                }

                if(response.status===3){

                    this.info_page.action = 'cancel';
                    this.info_page.header = this.$t('reservation.breadcrumb.cancel');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('reservation.breadcrumb.cancel');
                }
                this.statusBD = response.status;
                this.reservation = response;
                this.closeLoading();
            },
            sendForm(action){
                this.updateItem();
            },

            updateItem(){
                let response_send = {
                    _id: '',
                    status: 3

                };

                response_send._id  = this.reservation._id;
                response_send.status  = this.reservation.status;

                reservationService.updateReservation(response_send)
                    .then(async (response) => {
                        let reservationResponse = response.data.data.reservation;

                        let changes = logFunctions.cheackProp(this.info_page.original_info, reservationResponse, this.info_page.section_name)

                        
                        if(changes.length){
                            
                            if(reservationResponse.status === 3){
                                
                                changes.push({
                                    parameter: 'email',
                                    old: '',
                                    new: 'Email de cancelación enviado a:'+' '+reservationResponse.email
                                });
                               
                            }else{
                                
                                changes.push({
                                    parameter: 'email',
                                    old: '',
                                    new: 'Email de confirmación enviado a:'+' '+reservationResponse.email
                                });
                            }
                            await this.createLog(this.reservation._id, 1, changes);
                        }

                       if(reservationResponse.status === 3){

                           this.sendMail(reservationResponse._id, 'cancelation-mail', this.reservation.email)
                       }else{

                           this.sendMail(reservationResponse._id, 'view-mail', this.reservation.email)
                           //this.showSwal(this.$t('reservation.insides.update.msg'));
                       }
                    })
                    .catch(error => {
                       //console.log(error);
                    })
                    .finally(() => {
                        //this.showSwal(this.$t('reservation.insides.update.msg'));
                    });
            },

            dayName(dayIs){
                let days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
                return days[dayIs];
            },
            nightDiscount(dayIs){
                let days = ['','Primera', 'Segunda', 'Tercera', 'Cuarta', 'Quinta', 'Sexta', 'Séptima'];
                return days[dayIs];
            },

            nameAggregate(name){
                let auxString = '';
                let aggregates = this.aggregates;
                let aux = aggregates.find(item => item.value === name)
                console.log(aux);
                if(aux){
                    auxString =  aux.name
                }

                return auxString
            },
            constructString(qty, typeIs, textIs, night){
                let stringReturn = '';
                if(qty>1){
                    textIs = textIs+'s';
                }

                if(typeIs===1){
                    stringReturn = 'El máximo de '+textIs+' es '+qty+'  y aplica solo en la '+ this.nightDiscount(night) +' noche';
                }else{

                    stringReturn = 'El máximo de '+textIs+' es '+qty+'  por noche';
                }

                return stringReturn;
            },

            translateCountry(country){
                let auxString = country;
                let countries = this.info_page.countries;
                let aux = countries.find(item => item.value === country)
                if(aux){
                    auxString =  aux.name
                }

                return auxString
            },
            showSwal (msg) {

                    swal({
                        title: this.$t('reservation.insides.update.modal'),
                        text: msg,
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        type: 'success'
                    }).then(()=> {
                        this.$router.push({
                            name: 'Reservations'
                        });
                    });
            },
            getError (fieldName) {
                return this.errors.first(fieldName);
            },
            validate (action) {
                this.$validator.validateAll().then(isValid => {
                    if(isValid){
                        if(action==='add'){
                            this.saveItem();
                        }else{
                            this.updateItem();
                        }
                    }
                })
            },
            roomInfo(room_id){
                let auxString = '';
                let rooms = this.info_page.rooms;
                let aux = rooms.find(item => item._id === room_id)
                if(aux){
                    auxString =  aux.name
                }

                return
            },
            paymentType(value_){
                let stringAux = '';

               //console.log('value_', value_);
                let types_p = this.info_page.paymentTypes;

                let aux = types_p.find(typs =>  typs._id === value_)
                if(aux){
                    stringAux = aux.name;
                }
                return stringAux

            },

            formatConverte(date, formatType = null) {
                let date_return = null;

                switch (formatType) {
                    case 'txt':
                        date_return  =  moment(date, 'YYYY-MM-DD').format('D [de] MMMM YYYY')
                        break;
                    case 'time':
                        date_return  =  moment(date).utc().format('HH:mm')
                        break;
                    case 'time-txt':
                        date_return  =  moment(date, 'YYYY-MM-DD HH:mm').format('D [de] MMMM YYYY HH:mm')
                        break;

                    default:
                        date_return  =  moment(date, 'YYYY-MM-DD').locale('es').format('DD-MMM-YYYY')
                        date_return = date_return.replaceAll('.', '');
                        break;
                }
                return date_return;
            },
            annulmentConverte(value, typeA, formateA){
                //console.log('received:', value, typeA, formateA)

                let info_value = null,
                value_return = null;

                switch (formateA) {

                    case 'periodo':
                        info_value = this.reservation_info.cancellation_times.find(item => item.value === value)
                        value_return = info_value.name + ' antes de la llegada';

                        break;
                    case 'amount':
                        info_value = this.reservation_info.period_types.find(item => item.value === value && item.type === typeA)
                        value_return = info_value.name;
                        if(typeA === 1){
                            value_return = value_return +  '  ('+value+')'
                        }
                        break;
                }
                return value_return;
            },
            addSemaforo(reservation){
                let state = reservation.status;
               //console.log('state', state);
                let status = 'realizada';

                if(reservation.payment_status===false && (reservation.payment_method === 'PAYPAL' || reservation.payment_method === 'STRIPE' || reservation.payment_method === 'FIRSTDATA' || reservation.payment_method === 'TDD') && state ===1){
                    status = 'sin-concluir';
                }

                if(state===2){
                    status = 'realizada';
                }else if(state===3){
                    status = 'cancelada';
                }else if(state===4){
                    status = 'sin-concluir';
                }


                return status

            },
            capitalize(s) {
                if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1)
            },
            textSemaforo(reservation){
                let status =  reservation.status;

                if(reservation.payment_status===false && (reservation.payment_method === 'PAYPAL' || reservation.payment_method === 'STRIPE' || reservation.payment_method === 'FIRSTDATA' || reservation.payment_method === 'TDD') && status===1){
                    status = 4;
                }


                return this.reservation_info.states[status];

            },
            sumDays(date, numDays){
                return this.formatConverte(moment(date).utc().add(numDays, 'days'), 'txt');
            },
            diffDays(startDate, endDate){
                startDate = this.checkTimeZone(moment(startDate));
                endDate = this.checkTimeZone(moment(endDate));
                return (startDate.diff(endDate, 'days')*(-1));
            },

            checkTimeZone(date){
                let gTimeZone = settings.getTimezone();

                let tzdetect =  moment(date)
                    .utc(gTimeZone)

                return tzdetect;

            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            },
            async sendMail(id, type){
                let text = 'Enviando Email'
                swal({
                    title: text,
                    html:'<img src="/static/img/loading.svg" style="width: 100px"/>'
                    ,
                    showConfirmButton: false,
                    buttonsStyling: false
                })
                try {
                    let responseMail = await ApiFrontService.sendEmail(this.selected_property.booking_url, id, type)
                    if(responseMail.data.status === 200){


                        

                        let text = responseMail.data.msg;
                        swal({
                            title: text,
                            html:'<img src="/static/img/message.svg" style="width: 100px"/>',
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar',
                            buttonsStyling: false,
                            confirmButtonClass: 'btn btn-success btn-fill'
                        }).then(()=> {
                            this.$router.push({
                                name: 'Reservations'
                            });
                        });

                    }
                }catch (e) {
                    swal({
                        title: 'Ha ocurrido un error intentalo mas tarde',
                        html:'<img src="/static/img/message.svg" style="width: 100px"/>',
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar',
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill'
                    })
                }



            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            checkPriceType(price_type, array_validate){
                return array_validate.indexOf(price_type)>=0
             },
            formatMoney(value){
                return this.numberWithCommas(Math.round(value))
            },
             numberWithCommas(x) {
                    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
             },
            translateCanal(reservation){
                let channel = 'Directa';

                if(reservation.channel && reservation.channel==='ratetiger'){
                    channel = 'Rate Tiger';
                }
                return channel;
            },
            bgInfo(actionInfo, rateTigerCanal){
                let returnBg = '';
                if(rateTigerCanal){
                    returnBg = 'bg-channel-ext';
                }
                if(actionInfo === 'cancel'){
                    returnBg = 'bg-cancel';
                }


                return returnBg;

            }

        }
    }

</script>
<style lang="scss">
    .box-body{
        width: 100%;
    }

    ul.reorder-list {
        display: flex;
        flex-direction: column;
    }

    ul.reorder-list li.ordL {
        order: 3;
    }

    .preview-reservation{
        .bx-head{
            width: 100%;
            background-color: #666;
            padding: 15px 20px;
            &.bg-cancel{
                background-color: #cf2a27;
            }
            label{
                color: #ffffff;
                font-size: 16px;
                text-transform: none;
                font-weight: 600;
                display: inline-block;
                width: 100%;
                &.mid{
                    width: 50%;
                    vertical-align: top;
                    span{
                        width: 100%;
                        display: inline-block;
                        &.stat-cancel{
                            font-size: 20px
                        }
                    }
                }
            }
            .icon-action {
                font-size: 30px;
                position: absolute;
                right: 50px;
                top: 34px;
                color: #055394;
                &.plus-right {
                    right: 90px;
                }
            }
        }
        .bx-information{
            width: 100%;
            display: inline-block;
            .box-body{
                padding: 10px 15px;
                border: 2px solid #ebebeb;
                .item-line{
                    label.w-30p{
                        width: 200px;

                    }
                    label{
                        margin-bottom: 5px;
                        display: inline-block;
                        width: 50%;
                        font-size: 14px;
                        color: #0e0e0e;
                        text-transform: none;
                        &.item-quarter{
                            width: 25%;
                        }
                        &.item-third{
                            width: 13%;
                        }
                        &.item-third-b{
                            width: 24%;
                        }
                    }
                    .form-group{
                        margin-bottom: 5px;
                        display: inline-block;
                        width: 50%;
                        font-size: 14px;
                        color: #0e0e0e;
                        text-transform: none;
                        &.item-quarter{
                            width: 25%;
                        }
                        &.item-third{
                            width: 13%;
                        }
                        &.item-third-b{
                            width: 24%;
                        }
                    }
                }

                &.huesped{
                    .form-group{
                        width: 70%;
                    }
                    .item-line{
                        label {
                            width: 65%;
                            vertical-align: top;
                            ul{
                                padding-left: 20px;
                            }
                        }
                        label:first-child{
                            width: 30%;
                            vertical-align: top;
                        }
                    }
                }
                &.addons{
                    .form-group{
                        width: 70%;
                    }
                    .item-line{
                        &.mid-size{
                            width: 50%;
                            display: inline-table;
                        }
                        &.third-size-a{
                            width: 33%;
                            display: inline-table;
                            label {
                                width: 100%;
                            }
                        }
                        &.third-size-b{
                            width: 33%;
                            display: inline-table;
                            label {
                                width: 100%;
                            }
                        }
                        label {
                            width: 50%;
                            margin-bottom: 0;
                        }
                        label.sixty {
                            width: 65%;
                        }

                        label.sixty-b {
                            width: 30%;
                        }

                    }
                    .item-line.lastitem{
                        width: 45%;
                        padding-left: 5%;
                    }
                }
            }
        }
        .bx-history, .bx-politics{
            .box-body {
                padding: 10px 15px;
                border: 2px solid #ebebeb;
                &.pb-big{
                    padding: 10px 15px 65px;
                }
                .item-line {
                    label {
                        margin-bottom: 5px;
                        display: inline-block;
                        width: 100%;
                        font-size: 14px;
                        color: #0e0e0e;
                        text-transform: none;
                        padding-left: 10px;
                        &:first-child{
                             font-weight: 600;
                            padding-left: 0;
                        }
                    }
                }
            }

        }

        .bx-terms{
            .box-body{
                padding: 10px 15px;
                border: 2px solid #ebebeb;
            }
        }
        .bx-offers{
            .box-body{
                padding: 10px 15px;
                border: 2px solid #ebebeb;
                .item-row{
                    background: #ccc;

                    .item-line{
                        label{
                            font-size: 14px;
                            color: #0e0e0e;
                            text-transform: none;
                        }
                        &.complete{
                            label{
                                width: 100%;
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .is-bold{
                        font-weight: 600;
                    }
                    &:nth-child(odd) {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .state-reservation {
        label{
            margin-bottom: 5px;
            display: inline-block;
            font-size: 20px;
            color: #0e0e0e;
            text-transform: none;
            &.realizada{
                color: #1FBF64;

            }
            &.cancelada{
                color: #cf2a27;

            }
            &.sin-concluir{
                color: goldenrod;
            }
        }
    }
    .el-timeline {
       padding-left: 0;
    }
    .el-tabs__item.is-active {
        color: #6B3E91;
    }
    .el-tabs__active-bar{
        color: #6B3E91;
    }

    .border-bottom-sep {
        border-bottom: 1px solid #ebebeb;
    }
</style>
