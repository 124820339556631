import BackendService from './BackendService';

import { mapGetters } from "vuex";

export default class ReservationService extends BackendService {

    constructor() {
        super();
    }

    async getReservations(params) {


        //console.log('params', params);
        //let token = '';
        return this.httpCall('reservations',{
            params: params,
        });

    }
    async getReservationsDashboard(reservationsIds) {


        //console.log('params', params);
        //let token = '';
        return this.httpCall('reservations/dashboard',{
            method: 'POST',
            data: reservationsIds
        });

    }
    getReservationsCount(params) {
        return this.httpCall('reservations/count',{
            params: params,
        });

    }
    updateReservation(item){
        return this.httpCall('reservation/'+item._id,{
            method: 'PUT',
            data: item,
        });
    }



    /*getReservations(property_id) {
        let data = [
            {
                _id: 1,
                reservation_id: 'A16516',
                property_id: '5dd6d850026b56001786f851',
                status: 1,
                confirmation_no: 'XSA545',
                date_arrive: '2019-11-04',
                date_departure: '2019-11-07',
                room_type: 'Habitación estandar',
                room_id: 'room id',
                room_number: 1,
                package: 1,

                offer: '35% oferta especial (153645)',
                adults: 1,
                kids: 1,
                infantes: 1,
                jrs: 1,
                date_reservation: '2019-09-09',
                time_reservation: '10:50:33',
                cancel_reason: '',
                last_update: '2019-09-09',

                rateplan_id: '5dded1c9abc1e400170333c2',
                politic_id: '5e14acbd42704e0017a25115',
                politics: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ' +
                    'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ' +
                    'ullamco laboris nisi ut ',




                history: {
                    reservation_date: '2019-06-09',
                    reservation_time: '10:50:33',
                    cancellation_date: '2019-06-09',
                    cancellation_time: '10:50:33',
                    notification_date: '2019-06-09',
                    notification_time: '10:50:33',
                },
                offers_apply:
                    {
                        items:[
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            }
                        ],
                        subtotal: 'MXN $10000',
                        total: 'MXN $10000'
                    },



                rateplan: {
                    name: 'base'
                },
                payment_info: {
                    type: 'Tarjeta',
                    id_transaccion: 'ADSDVd4565b4',
                    deposite: '2019-11-04',
                    amount: 'MXN $2500'
                },


                client_info: {
                    name: 'Edgar Manuel Anaya Rodriguez',
                    email: 'manuel@aol.com',
                    phone: '+523221167278',
                    address: 'Palmar de Aramara',
                    city: 'Puerto Vallarta',
                    state: 'Jalisco',
                    zc: '48314',
                    country: 'Mexico'
                },

            },
            {
                _id: 2,
                reservation_id: 'A16516',
                property_id: '5dd6d850026b56001786f851',
                status: 2,
                confirmation_no: 'XSA545',
                date_arrive: '2019-11-04',
                date_departure: '2019-11-07',
                room_type: 'Habitación estandar',
                room_number: 1,
                package: 2,

                offer: '35% oferta especial (153645)',
                adults: 1,
                kids: 1,
                infantes: 1,
                jrs: 1,
                date_reservation: '2019-09-09',
                time_reservation: '10:50:33',
                cancel_reason: '',
                last_update: '2019-09-09',

                rateplan_id: '5dded1c9abc1e400170333c2',
                politic_id: '5e14acbd42704e0017a25115',
                politics: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ' +
                    'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ' +
                    'ullamco laboris nisi ut ',




                history: {
                    reservation_date: '2019-06-09',
                    reservation_time: '10:50:33',
                    cancellation_date: '2019-06-09',
                    cancellation_time: '10:50:33',
                    notification_date: '2019-06-09',
                    notification_time: '10:50:33',
                },
                offers_apply:
                    {
                        items:[
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            }
                        ],
                        subtotal: 'MXN $10000',
                        total: 'MXN $10000'
                    },



                rateplan: {
                    name: 'base'
                },
                payment_info: {
                    type: 'Tarjeta',
                    id_transaccion: 'ADSDVd4565b4',
                    deposite: '2019-11-04',
                    amount: 'MXN $2500'
                },


                client_info: {
                    name: 'Edgar Manuel Anaya Rodriguez',
                    email: 'manuel@aol.com',
                    phone: '+523221167278',
                    address: 'Palmar de Aramara',
                    city: 'Puerto Vallarta',
                    state: 'Jalisco',
                    zc: '48314',
                    country: 'Mexico'
                },

            },
            {
                _id: 3,
                reservation_id: 'A16516',
                property_id: '5dd6d850026b56001786f851',
                status: 1,
                confirmation_no: 'XSA545',
                date_arrive: '2019-11-04',
                date_departure: '2019-11-07',
                room_type: 'Habitación estandar',
                room_number: 1,
                package: 2,

                offer: '35% oferta especial (153645)',
                adults: 1,
                kids: 1,
                infantes: 1,
                jrs: 1,
                date_reservation: '2019-09-09',
                time_reservation: '10:50:33',
                cancel_reason: '',
                last_update: '2019-09-09',

                rateplan_id: '5dded1c9abc1e400170333c2',
                politic_id: '5e14acbd42704e0017a25115',
                politics: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ' +
                    'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ' +
                    'ullamco laboris nisi ut ',




                history: {
                    reservation_date: '2019-06-09',
                    reservation_time: '10:50:33',
                    cancellation_date: '2019-06-09',
                    cancellation_time: '10:50:33',
                    notification_date: '2019-06-09',
                    notification_time: '10:50:33',
                },
                offers_apply:
                    {
                        items:[
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            }
                        ],
                        subtotal: 'MXN $10000',
                        total: 'MXN $10000'
                    },



                rateplan: {
                    name: 'base'
                },
                payment_info: {
                    type: 'Tarjeta',
                    id_transaccion: 'ADSDVd4565b4',
                    deposite: '2019-11-04',
                    amount: 'MXN $2500'
                },


                client_info: {
                    name: 'Edgar Manuel Anaya Rodriguez',
                    email: 'manuel@aol.com',
                    phone: '+523221167278',
                    address: 'Palmar de Aramara',
                    city: 'Puerto Vallarta',
                    state: 'Jalisco',
                    zc: '48314',
                    country: 'Mexico'
                },

            },
            {
                _id: 4,
                reservation_id: 'A16516',
                property_id: '5dd6d850026b56001786f851',
                status: 1,
                confirmation_no: 'XSA545',
                date_arrive: '2019-11-04',
                date_departure: '2019-11-07',
                room_type: 'Habitación estandar',
                room_number: 1,
                package: 2,

                offer: '35% oferta especial (153645)',
                adults: 1,
                kids: 1,
                infantes: 1,
                jrs: 1,
                date_reservation: '2019-09-09',
                time_reservation: '10:50:33',
                cancel_reason: '',
                last_update: '2019-09-09',

                rateplan_id: '5dded1c9abc1e400170333c2',
                politic_id: '5e14acbd42704e0017a25115',
                politics: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ' +
                    'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ' +
                    'ullamco laboris nisi ut ',




                history: {
                    reservation_date: '2019-06-09',
                    reservation_time: '10:50:33',
                    cancellation_date: '2019-06-09',
                    cancellation_time: '10:50:33',
                    notification_date: '2019-06-09',
                    notification_time: '10:50:33',
                },
                offers_apply:
                    {
                        items:[
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            }
                        ],
                        subtotal: 'MXN $10000',
                        total: 'MXN $10000'
                    },



                rateplan: {
                    name: 'base'
                },
                payment_info: {
                    type: 'Tarjeta',
                    id_transaccion: 'ADSDVd4565b4',
                    deposite: '2019-11-04',
                    amount: 'MXN $2500'
                },


                client_info: {
                    name: 'Edgar Manuel Anaya Rodriguez',
                    email: 'manuel@aol.com',
                    phone: '+523221167278',
                    address: 'Palmar de Aramara',
                    city: 'Puerto Vallarta',
                    state: 'Jalisco',
                    zc: '48314',
                    country: 'Mexico'
                },

            },
            {
                _id: 5,
                reservation_id: 'A16516',
                property_id: '5dd6d850026b56001786f851',
                status: 1,
                confirmation_no: 'XSA545',
                date_arrive: '2019-11-04',
                date_departure: '2019-11-07',
                room_type: 'Habitación estandar',
                room_number: 1,
                package: 2,

                offer: '35% oferta especial (153645)',
                adults: 1,
                kids: 1,
                infantes: 1,
                jrs: 1,
                date_reservation: '2019-09-09',
                time_reservation: '10:50:33',
                cancel_reason: '',
                last_update: '2019-09-09',

                rateplan_id: '5dded1c9abc1e400170333c2',
                politic_id: '5e14acbd42704e0017a25115',
                politics: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ' +
                    'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ' +
                    'ullamco laboris nisi ut ',




                history: {
                    reservation_date: '2019-06-09',
                    reservation_time: '10:50:33',
                    cancellation_date: '2019-06-09',
                    cancellation_time: '10:50:33',
                    notification_date: '2019-06-09',
                    notification_time: '10:50:33',
                },
                offers_apply:
                    {
                        items:[
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            },
                            {
                                date:'2019-06-09',
                                description:'Tarifa diaria',
                                importe:'MXN $2500',
                            }
                        ],
                        subtotal: 'MXN $10000',
                        total: 'MXN $10000'
                    },



                rateplan: {
                    name: 'base'
                },
                payment_info: {
                    type: 'Tarjeta',
                    id_transaccion: 'ADSDVd4565b4',
                    deposite: '2019-11-04',
                    amount: 'MXN $2500'
                },


                client_info: {
                    name: 'Edgar Manuel Anaya Rodriguez',
                    email: 'manuel@aol.com',
                    phone: '+523221167278',
                    address: 'Palmar de Aramara',
                    city: 'Puerto Vallarta',
                    state: 'Jalisco',
                    zc: '48314',
                    country: 'Mexico'
                },

            },
        ]



        return data.filter(item => item.property_id === property_id);



    }
    getReservation(reservationId){

        let info = this.getReservations('5dd6d850026b56001786f851');

        console.log('tst', reservationId, info)
        info = info.find(item => item._id === reservationId)
        return info;
    }*/
    getReservation(reservationId){
        return this.httpCall('reservations',{
            params: {
                id:reservationId
            },
        });
    }

    getReservationOne(reservationId){
        return this.httpCall('reservation/'+reservationId);
    }




}
