import axios from "axios";



export default {
    // LOGIN
    sendMail(url, id) {
        url = url+'/api/'


        let apiClient = axios.create({
            baseURL: url
        })


        return apiClient.get("view-mail/"+id+'?back=true');
    },
    sendEmail(url, id, type) {
        url = url+'/api/'


        let apiClient = axios.create({
            baseURL: url
        })


        //console.log(apiClient.defaults.headers.common.token, 'apiClient');
        delete apiClient.defaults.headers.common.token;



        return apiClient.get("send-mail/"+id+'?type='+type);
    },

};
